import StoreInfo from '@common/models/StoreInfo';
import { FeaturesSliceType } from '@common/store/features/slice';
import { FilterBarSliceType } from '@common/store/filterBar/slice';
import { PageHeaderSliceType } from '@common/store/pageHeader/slice';
import { PoliciesSliceTosType } from '@common/store/policiesTos/slice';
import { getActiveNavbar } from '@common/utils';
import { isMobile } from '@common/utils';
import Email from '@images/Email.svg';
import Phone from '@images/Phone-call.svg';
import Pin from '@images/Pin.svg';
import Image from '@lib/Image';
import Link from '@lib/Link';
import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../../common/store';

interface FooterProps {
  storeInfo: StoreInfo;
  features: FeaturesSliceType;
  filterBar: FilterBarSliceType;
  policies: PoliciesSliceTosType;
  pageHeader: PageHeaderSliceType;
  pageHeaderID: number;
}

const Footer: React.FunctionComponent<FooterProps> = ({
  storeInfo,
  features,
  filterBar,
  policies: { policyTos },
  pageHeader,
  pageHeaderID,
}) => {
  const quickLinks = 'mb-14 md:mb-16 flex text-text_variant text-14 md:text-16 leading-24 font-normal';
  const header = 'text-footer-heading leading-24 text-18 md:text-20 mb-20 font-medium';
  const arePoliciesPresent = Object.keys(policyTos).length > 0;
  const pageHeaderLayout = pageHeader[pageHeaderID]?.pageHeader;
  return (
    <div className="w-full container mx-auto mt-50" color-customisation="footer-bg" id="page-footer">
      <div className="border-slideBorder border-0 border-t-2">
        <div className="block md:hidden py-20 ">
          <Link to="/" className="flex rounded  w-111  overflow-hidden my-20" id="mobile-footer-logo">
            {pageHeaderLayout?.logo ? (
              <Image
                noDefaultDimensions
                src={pageHeaderLayout?.logo}
                alt={storeInfo?.storename}
                className="overflow-hidden object-contain h-42"
              />
            ) : (
              <div className="md:font-medium text-20 text-footer-heading">{storeInfo?.storename}</div>
            )}
          </Link>
          <div className="my-30 md:mb-0">
            {/* <p className={quickLinks}>{storeInfo?.storename}</p> */}
            {storeInfo?.contactInfo?.address ? (
              <p
                className={quickLinks}
                font-customisation="para-text"
                color-customisation="footer-links"
                id="mobile-footer-contact-address"
              >
                <Image noDefaultDimensions={true} src={Pin} alt="pin" className="mr-14" />
                {storeInfo?.contactInfo?.address}
              </p>
            ) : null}
            {storeInfo?.contactInfo?.number ? (
              <p className={quickLinks} font-customisation="para-text" color-customisation="footer-links">
                <Image
                  noDefaultDimensions={true}
                  src={Phone}
                  alt="Phone"
                  className="mr-14"
                  style={{ filter: 'brightness(0.3)' }}
                />
                <a href={`tel:${storeInfo?.contactInfo?.number}`} id="mobile-footer-contact-number">
                  {storeInfo?.contactInfo?.number}
                </a>
              </p>
            ) : null}
            {storeInfo?.contactInfo?.email ? (
              <p className={quickLinks} font-customisation="para-text" color-customisation="footer-links">
                <Image
                  noDefaultDimensions={true}
                  src={Email}
                  alt="Email"
                  className="mr-14"
                  style={{ filter: 'brightness(0.3)' }}
                />
                <a
                  href={`${isMobile() ? 'mailto:' : 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to='}${
                    storeInfo?.contactInfo?.email
                  }`}
                  className="underline"
                >
                  <p className="w-full" id="mobile-footer-contact-mail">
                    {storeInfo?.contactInfo?.email}
                  </p>
                </a>
              </p>
            ) : null}
          </div>
        </div>
        <div className=" py-20 md:py-70 justify-between">
          <div className="grid grid-cols-2 sm:grid-cols-5 gap-10 md:px-4">
            <div className="md:block hidden">
              <Link to="/" id="footer-logo">
                {pageHeaderLayout?.logo ? (
                  <Image
                    noDefaultDimensions={true}
                    src={pageHeaderLayout?.logo}
                    alt={storeInfo?.storename}
                    className=" h-42 rounded"
                  />
                ) : (
                  <div className="font-medium text-26 -mt-6 text-footer-heading">{storeInfo?.storename}</div>
                )}
              </Link>
              <div className="md:mb-0 mt-12">
                {/* <p className={quickLinks}>{storeInfo?.storename}</p> */}
                {storeInfo?.contactInfo?.address ? (
                  <p
                    className={quickLinks}
                    id="footer-contact-address"
                    font-customisation="para-text"
                    color-customisation="footer-links"
                  >
                    <Image noDefaultDimensions={true} src={Pin} alt="Email" className="mr-14" />
                    {storeInfo?.contactInfo?.address}
                  </p>
                ) : null}
                {storeInfo?.contactInfo?.number && (
                  <p className={quickLinks} font-customisation="para-text" color-customisation="footer-links">
                    <Image
                      noDefaultDimensions={true}
                      src={Phone}
                      alt="Email"
                      className="mr-14"
                      style={{ filter: 'brightness(0.5)' }}
                    />
                    <a href={`tel:${storeInfo?.contactInfo?.number}`} id="footer-contact-number">
                      {storeInfo?.contactInfo?.number}
                    </a>
                  </p>
                )}
                {storeInfo?.contactInfo?.email ? (
                  <p className={quickLinks} font-customisation="para-text" color-customisation="footer-links">
                    <Image
                      noDefaultDimensions={true}
                      src={Email}
                      alt="Email"
                      className="mr-14"
                      style={{ filter: 'brightness(0.5)' }}
                    />
                    <a
                      href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${storeInfo?.contactInfo?.email}`}
                      className="underline"
                      id="footer-contact-mail"
                    >
                      <p className="w-160 md:w-full break-all">{storeInfo?.contactInfo?.email}</p>
                    </a>
                  </p>
                ) : null}
              </div>
            </div>
            <div className="mb-30 md:mb-0">
              <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                Menu
              </div>
              {arePoliciesPresent && !policyTos?.privacy && (
                <Link
                  to="/policy/privacy"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-privacy"
                >
                  Privacy
                </Link>
              )}
              {getActiveNavbar('FAQs', filterBar) && (
                <Link
                  to="/faqs"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-faqs"
                >
                  FAQs
                </Link>
              )}
              {arePoliciesPresent && !policyTos?.termsAndConditions && (
                <Link
                  to="/policy/tnc"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-terms-conditions"
                >
                  Terms & Conditions
                </Link>
              )}
            </div>
            <div className="mb-30 md:mb-0">
              <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                Account
              </div>
              <Link
                id="footer-track-order"
                to="/track-order"
                className={quickLinks}
                font-customisation="para-text"
                color-customisation="footer-links"
              >
                Track Order
              </Link>
              {arePoliciesPresent && !policyTos?.cancelAndRefund && (
                <Link
                  id="footer-cancellation-refund"
                  to="/policy/cancellation"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                >
                  Cancellation &amp; Refund
                </Link>
              )}
              {arePoliciesPresent && !policyTos?.shippingAndDelivery && (
                <Link
                  id="footer-shipping-delivery"
                  to="/policy/shipping"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                >
                  Shipping &amp; Delivery
                </Link>
              )}
            </div>
            <div className="mb-30 md:mb-0">
              <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                Company
              </div>
              {features.features?.contactUsPage && getActiveNavbar('Contact Us', filterBar) ? (
                <Link
                  id="footer-contact-us"
                  to="/contactus"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                >
                  Contact Us
                </Link>
              ) : null}
              {features?.features?.customPages && getActiveNavbar('About Us', filterBar) ? (
                <Link
                  id="footer-about-us"
                  to="/aboutus"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                >
                  About Us
                </Link>
              ) : null}
            </div>
            {storeInfo?.hasSocialLinks ? (
              <div className="mb-30 md:mb-0">
                <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                  Stay Connected
                </div>
                <div className=" items-center" font-customisation="para-text">
                  {storeInfo?.social?.facebook ? (
                    <a
                      className={quickLinks}
                      color-customisation="footer-links"
                      href={storeInfo?.social?.facebook}
                      target="_black()"
                      id="footer-facebook-link"
                      title="Facebook"
                    >
                      Facebook
                    </a>
                  ) : null}
                  {storeInfo?.social?.twitter ? (
                    <a
                      className={quickLinks}
                      color-customisation="footer-links"
                      href={storeInfo?.social?.twitter}
                      target="_black()"
                      id="footer-twitter-link"
                      title="Twitter"
                    >
                      Twitter
                    </a>
                  ) : null}
                  {storeInfo?.social?.instagram ? (
                    <a
                      className={quickLinks}
                      color-customisation="footer-links"
                      href={storeInfo?.social?.instagram}
                      target="_black()"
                      id="footer-instagram-link"
                      title="Instagram"
                    >
                      Instagram
                    </a>
                  ) : null}
                  {storeInfo?.social?.pinterest ? (
                    <a
                      className={quickLinks}
                      color-customisation="footer-links"
                      href={storeInfo?.social?.pinterest}
                      target="_black()"
                      id="footer-pinterest-link"
                      title="Pinterest"
                    >
                      Pinterest
                    </a>
                  ) : null}
                  {storeInfo?.social?.youtube ? (
                    <a
                      className={quickLinks}
                      color-customisation="footer-links"
                      href={storeInfo?.social?.youtube}
                      target="_black()"
                      id="footer-youtube-link"
                      title="YouTube"
                    >
                      Youtube
                    </a>
                  ) : null}
                  {storeInfo?.social?.linkedin ? (
                    <a
                      className={quickLinks}
                      color-customisation="footer-links"
                      href={storeInfo?.social?.linkedin}
                      target="_black()"
                      id="footer-linkedin-link"
                      title="LinkedIn"
                    >
                      Linkedin
                    </a>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(({ pageHeader }: RootState) => ({ pageHeader }))(Footer);
