import React from 'react';
export interface CtaProps {
  text: string;
  customClass?: string;
  link: string;
}

const Cta: React.FC<CtaProps> = ({ link, customClass, text, ...props }) => {
  return (
    <>
      {text && link ? (
        <a
          href={link}
          className={`w-158 md:w-154 min-h-40 py-8 px-12 text-center min-h-48 text-14 md:text-16 leading-20 md:leading-24 border-theme border-2 bg-theme text-white font-medium focus:outline-none rounded-full disabled:cursor-not-allowed disabled:opacity-50 max-w-220 flex justify-center items-center hover:border-theme hover:border-2 hover:text-theme hover:bg-white ${customClass}`}
          target="_blank"
          rel="noreferrer"
          font-customisation="para-text"
          button-customisation="page-button"
          {...props}
        >
          {text}
        </a>
      ) : null}
    </>
  );
};

export default Cta;
