// import { ReactComponent as ToastCloseIcon } from '@common/icons/ToastClose.svg';
import { ToastWithKeyType } from '@common/store/toasts/slice';
import { addToast, removeToast } from '@common/store/toasts/slice';
import blackClose from '@images/whiteClose.svg';
// import Close from '@images/whiteClose.svg';
import classnames from 'classnames';
import React from 'react';

import { WISHLIST_TOAST } from '../../../../common/store/wishList/slice';
export interface ToastsProps {
  toasts: ToastWithKeyType[];
  addToast: typeof addToast;
  removeToast: typeof removeToast;
  quickView?: boolean;
  viewCart?: () => void;
}

const Toasts: React.FC<ToastsProps> = ({ toasts, removeToast, quickView }) => {
  setTimeout(() => {
    if (toasts.length) {
      document.getElementById('QuickViewToasts')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, 1000);

  return (
    <>
      {quickView ? (
        <div className={classnames('z-999 relative md:absolute md:right-0 md:w-full')} font-customisation="para-text">
          {toasts.map((toast) => (
            <div key={toast.key}>
              <div
                className={
                  toast.variant === 'bg-toast-error'
                    ? 'bg-errorToast text-white mb-16 md:mb-24 rounded shadow-md'
                    : 'bg-successToast text-white mb-16 md:mb-24 rounded shadow-md'
                }
              >
                <div
                  className={classnames(
                    'container mx-auto w-full flex font-medium items-center py-10 md:py-14 text-14 md:text-16 justify-between px-10 border-0',
                  )}
                >
                  <div font-customisation="para-text" dangerouslySetInnerHTML={{ __html: toast.content }}></div>
                  <img
                    src={blackClose}
                    alt="Close"
                    className="mx-16 cursor-pointer h-10 md:h-14"
                    onClick={() => removeToast(toast.key)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        toasts.map((toast) => (
          <div key={toast.key} font-customisation="para-text">
            {toast?.options?.find((option) => option?.name == WISHLIST_TOAST) ? (
              <div className={`shadow-lg ${toast.variant}`}>
                <div className="container mx-auto flex items-center py-16 text-15 text-white justify-between">
                  <div dangerouslySetInnerHTML={{ __html: toast.content }}></div>
                  <img
                    src={blackClose}
                    alt="Close"
                    className="mx-16 cursor-pointer h-10 md:h-14"
                    onClick={() => removeToast(toast.key)}
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-wrap justify-center w-full cursor-pointer">
                <div className={classnames('w-fit')}>
                  <div
                    className={
                      toast.variant === 'bg-toast-error' ? 'bg-errorToast text-white my-16 rounded shadow-md' : 'hidden'
                    }
                  >
                    <div
                      className={classnames(
                        'container px-20 mx-auto w-full flex font-medium items-center py-12 text-14 md:text-16 justify-between',
                      )}
                    >
                      <div dangerouslySetInnerHTML={{ __html: toast.content }}></div>
                      <img
                        src={blackClose}
                        alt="Close"
                        className="mx-16 cursor-pointer h-10 md:h-14"
                        onClick={() => removeToast(toast.key)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))
      )}
    </>
  );
};

export default Toasts;
